<template>
    <div class="popup_sub_container add_contact_adj">
        <h2 v-if="edit">EDIT CONTACT</h2>
        <h2 v-else>ADD CONTACT</h2>
        <i class="closeAddContact" @click="closeModal">X</i>
        <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
            <section>
                <h3 class="sub_head">PRIMARY ADDRESS</h3>
                <div class="form_section1">
                    <table>
                        <tr>
                            <td>
                                <ValidationProvider
                                    name="name"
                                    :rules="`required|max:${max5}`"
                                    v-slot="{ errors }"
                                    slim
                                >
                                    <div class="label_and_element_wrapper">
                                        <label>
                                            Full Name
                                            <span>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            v-model="address.name"
                                            :placeholder="`Full Name Character Limit ${max5}`"
                                            :class="
                                                errors[0]
                                                    ? 'warning-border'
                                                    : ''
                                            "
                                            :maxlength="max5"
                                        />
                                    </div>
                                </ValidationProvider>
                            </td>
                            <td>
                                <div class="label_and_element_wrapper">
                                    <label>
                                        Branch Name
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            v-model="address.branch_name"
                                            placeholder="Branch Name Character Limit 30"
                                            class="dynamic_data_trigger"
                                            :maxlength="max2"
                                        />
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="label_and_element_wrapper">
                                    <label>
                                        Department Name
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            v-model="address.department_id"
                                            placeholder="Department Name Character Limit 30"
                                            class="dynamic_data_trigger"
                                            :maxlength="max2"
                                        />
                                    </div>
                                </div>
                            </td>
                            <td>
                                <!-- <ValidationProvider name="company name" rules="required|max:30" v-slot="{ errors }" slim> -->
                                <div class="label_and_element_wrapper">
                                    <label>
                                        Company
                                        <!-- <span>*</span> -->
                                    </label>
                                    <input
                                        type="text"
                                        v-model="address.company_name"
                                        :placeholder="`Company Character Limit ${max5}`"
                                        :maxlength="max5"
                                    />
                                </div>
                                <!-- </ValidationProvider> -->
                            </td>
                        </tr>
						<tr>
							<td>
								<div class="label_and_element_wrapper">
                                    <label>
                                        Floor Number
                                    </label>
                                    <input
                                        type="text"
                                        v-model="address.floor"
                                        placeholder="Floor Number Character Limit 60"
                                        :maxlength="max"
                                    />
                                </div>
							</td>
							<td>
								<div class="label_and_element_wrapper">
                                    <label>
                                        Desk Number
                                    </label>
                                    <input
                                        type="text"
                                        v-model="address.desk"
                                        placeholder="Desk Number Character Limit 60"
                                        :maxlength="max"
                                    />
                                </div>
							</td>
						</tr>
                        <tr>
                            <td>
                                <ValidationProvider
                                    name="address1"
                                    :rules="`required|max:${max5}`"
                                    v-slot="{ errors }"
                                    slim
                                >
                                    <div class="label_and_element_wrapper">
                                        <label>
                                            Address line 1
                                            <span>*</span>
                                        </label>
                                        <div>
                                            <input
                                                type="text"
                                                v-model="address.address_1"
                                                :placeholder="`Address line 1 Character Limit ${max5}`"
                                                ref="address_1"
                                                @focus="fetchLocationApi('address_1')"
                                                :maxlength="max5"
                                                :class="errors[0] ? 'warning-border' : ''"
                                            />
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </td>
                            <td>
                                <!-- <ValidationProvider name="address 2" rules="required|max:60" v-slot="{ errors }" slim> -->
                                <div class="label_and_element_wrapper">
                                    <label>
                                        Address line 2
                                        <!--  <span>*</span> -->
                                    </label>
                                    <input
                                        type="text"
                                        v-model="address.address_2"
                                        :placeholder="`Address line 2 Character Limit ${max5}`"
                                        :maxlength="max5"
                                    />
                                </div>
                                <!--  </ValidationProvider> -->
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <!-- <ValidationProvider name="postal code" rules="required|max:12" v-slot="{ errors }" slim> -->
                                <div class="label_and_element_wrapper">
                                    <label>
                                        Postal Code
                                        <!-- <span>*</span> -->
                                    </label>
                                    <input
                                        type="text"
                                        v-model="address.postal_code"
                                        placeholder="Postal Code Character Limit 10"
                                        :maxlength="max1"
                                    />
                                </div>
                                <!-- </ValidationProvider> -->
                            </td>
                            <td>
                                <!-- <ValidationProvider name="city" rules="required|max:30" v-slot="{ errors }" slim> -->
                                <div class="label_and_element_wrapper">
                                    <label>
                                        City
                                        <!--  <span>*</span> -->
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="City Character Limit 30"
                                        v-model="address.city"
                                        :maxlength="max2"
                                    />
                                </div>
                                <!-- </ValidationProvider> -->
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <ValidationProvider
                                    name="suburb"
                                    rules="required|max:30"
                                    v-slot="{ errors }"
                                    slim
                                >
                                    <div class="label_and_element_wrapper">
                                        <label>
                                            Suburb
                                            <span>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            v-model="address.suburb"
                                            placeholder="Suburb Character Limit 30"
                                            :maxlength="max2"
                                            :class="
                                                errors[0]
                                                    ? 'warning-border'
                                                    : ''
                                            "
                                        />
                                    </div>
                                </ValidationProvider>
                            </td>
                            <td>
                                <ValidationProvider
                                    name="country"
                                    rules="required|max:30"
                                    v-slot="{ errors }"
                                    slim
                                >
                                    <div class="label_and_element_wrapper">
                                        <label>
                                            Country
                                            <span>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            v-model="address.country"
                                            placeholder="Country Character Limit 30"
                                            :maxlength="max2"
                                            :class="
                                                errors[0]
                                                    ? 'warning-border'
                                                    : ''
                                            "
                                        />
                                    </div>
                                </ValidationProvider>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <ValidationProvider
                                    name="state"
                                    rules="required|max:30"
                                    v-slot="{ errors }"
                                    slim
                                >
                                    <div class="label_and_element_wrapper">
                                        <label>
                                            State/Province
                                            <span>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            v-model="address.state"
                                            placeholder="State/Province Character Limit 30"
                                            :maxlength="max2"
                                            :class="
                                                errors[0]
                                                    ? 'warning-border'
                                                    : ''
                                            "
                                        />
                                    </div>
                                </ValidationProvider>
                            </td>

                            <td>
                                <!-- <ValidationProvider name="email" rules="required|max:60" v-slot="{ errors }" slim> -->
                                <div class="label_and_element_wrapper">
                                    <label>
                                        Email
                                        <!--   <span>*</span> -->
                                    </label>
                                    <input
                                        type="text"
                                        v-model="address.email"
                                        placeholder="Email Character Limit 60"
                                        :maxlength="max"
                                    />
                                </div>
                                <!-- </ValidationProvider> -->
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <ValidationProvider
                                    name="phone"
                                    rules="required|max:30"
                                    v-slot="{ errors }"
                                    slim
                                >
                                    <div class="label_and_element_wrapper">
                                        <label>
                                            Phone
                                            <span>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            v-model="address.phone"
                                            placeholder="Phone Character Limit 20"
                                            :maxlength="max4"
                                            :class="
                                                errors[0]
                                                    ? 'warning-border'
                                                    : ''
                                            "
                                        />
                                    </div>
                                </ValidationProvider>
                            </td>
                        </tr>
                    </table>
                </div>
            </section>
            <hr />
            <span class="prefered_add"
                >Add an additional preferred address</span
            >

            <input
                type="checkbox"
                class="chk_prefered"
                value="1"
                v-bind:true-value="1"
                v-bind:false-value="0"
                v-model="address.preferred_address"
            />

            <hr />
            <section class="preferred_hide">
                <h3 class="sub_head">PREFERRED ADDRESS</h3>
                <div class="form_section1">
                    <table>
                        <tr>
                            <td>
                                <ValidationProvider
                                    name="address_1"
                                    rules="required|max:60"
                                    v-slot="{ errors }"
                                    slim
                                >
                                    <div class="label_and_element_wrapper">
                                        <label>
                                            Address line 1
                                            <span>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="Address line 1 Character Limit 60"
                                            v-model="
                                                address.preferred.address_1
                                            "
                                            :maxlength="max"
                                            :class="
                                                errors[0]
                                                    ? 'warning-border'
                                                    : ''
                                            "
                                        />
                                    </div>
                                </ValidationProvider>
                            </td>
                            <td>
                                <!-- <ValidationProvider name="address_2" rules="required|max:60" v-slot="{ errors }" slim> -->
                                <div class="label_and_element_wrapper">
                                    <label>
                                        Address line 2
                                        <!-- <span>*</span> -->
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Address line 2 Character Limit 60"
                                        v-model="address.preferred.address_2"
                                        :maxlength="max"
                                    />
                                </div>
                                <!--   </ValidationProvider> -->
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <!-- <ValidationProvider name="postal code p" rules="required|max:60" v-slot="{ errors }" slim>  -->
                                <div class="label_and_element_wrapper">
                                    <label>
                                        Postal Code
                                        <!-- <span>*</span> -->
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Postal Code Character Limit 10"
                                        v-model="address.preferred.postal_code"
                                        :maxlength="max1"
                                    />
                                </div>
                                <!-- </ValidationProvider> -->
                            </td>
                            <td>
                                <!--  <ValidationProvider name="city p" rules="required|max:30" v-slot="{ errors }" slim> -->
                                <div class="label_and_element_wrapper">
                                    <label>
                                        City
                                        <!--  <span>*</span> -->
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="City Character Limit 30"
                                        v-model="address.preferred.city"
                                        :maxlength="max2"
                                    />
                                </div>
                                <!-- </ValidationProvider> -->
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <ValidationProvider
                                    name="suburb p"
                                    rules="required|max:30"
                                    v-slot="{ errors }"
                                    slim
                                >
                                    <div class="label_and_element_wrapper">
                                        <label>
                                            Suburb
                                            <span>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="Suburb Character Limit 30"
                                            v-model="address.preferred.suburb"
                                            :maxlength="max2"
                                            :class="
                                                errors[0]
                                                    ? 'warning-border'
                                                    : ''
                                            "
                                        />
                                    </div>
                                </ValidationProvider>
                            </td>
                            <td>
                                <ValidationProvider
                                    name="state p"
                                    rules="required|max:30"
                                    v-slot="{ errors }"
                                    slim
                                >
                                    <div class="label_and_element_wrapper">
                                        <label>
                                            State/Province
                                            <span>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="State/Province Character Limit 30"
                                            v-model="address.preferred.state"
                                            :maxlength="max2"
                                            :class="
                                                errors[0]
                                                    ? 'warning-border'
                                                    : ''
                                            "
                                        />
                                    </div>
                                </ValidationProvider>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <ValidationProvider
                                    name="country p"
                                    rules="required|max:30"
                                    v-slot="{ errors }"
                                    slim
                                >
                                    <div class="label_and_element_wrapper">
                                        <label>
                                            Country
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="Country Character Limit 30"
                                            v-model="address.preferred.country"
                                            :maxlength="max2"
                                            :class="
                                                errors[0]
                                                    ? 'warning-border'
                                                    : ''
                                            "
                                        />
                                    </div>
                                </ValidationProvider>
                            </td>
                        </tr>
                    </table>
                </div>
            </section>
        </ValidationObserver>
        <section class="centered">
            <a class="link_bt bt_cancel" @click="closeModal">Cancel</a>
            <a class="link_bt bt_save" @click="submit">Save</a>
        </section>
    </div>
</template>
<script>
export default {
    name: "AddContact",
    data() {
        return {
            max: 60,
            max2: 30,
            max1: 10,
            max4: 20,
            max5: 70,
            modalStatus: false,
            address: {
                category: "",
                name: "",
                company_name: "",
                branch_name: "",
                department_id: "",
                cost_center_id: "",
                delivery_location: "",
                address_1: "",
                address_2: "",
                postal_code: "",
                country: "",
                city: "",
                suburb: "",
                state: "",
                phone: "",
                email: "",
                preferred_address: "0",
                preferred: {
                    address_1: "",
                    address_2: "",
                    postal_code: "",
                    country: "",
                    city: "",
                    suburb: "",
                    state: "",
                },
            },
            branches: [],
            departments: [],
            costs: [],
        };
    },
    created() {
        this.fetchBranch(false);
        this.fetchDepartment();
        if (this.edit) {
            this.populateAddress();
        }
    },
    methods: {
        closeModal() {
            this.modalStatus = false;
            this.$emit("closeModal", this.modalStatus);
        },
        fetchBranch(loaddetails = true) {
            this.axios
                .get("/api/branch/view")
                .then((response) => {
                    this.branches = response.data.branches;
                })
                .catch((error) => {
                    console.log(error);
                });
            if (loaddetails) {
                this.fetchBranchDetail();
            }
        },
        fetchBranchDetail() {
            this.axios
                .get("/api/branch/view/" + this.address.branch_name)
                .then((response) => {
                    let branch = response.data.branches;
                    this.address.company_name = branch.company.name;
                    this.address.address_1 = branch.address_1;
                    this.address.address_2 = branch.address_2;
                    this.address.postal_code = branch.postal_code;
                    this.address.country = branch.country;
                    this.address.city = branch.city;
                    this.address.suburb = branch.suburb;
                    this.address.state = branch.state;
                    this.address.email = branch.email;
                    this.address.department_id = "";
                    this.fetchDepartment();
                })
                .catch((error) => {
                    this.toast.error(error);
                });
        },
        // fetchDepartment(){
        //   this.axios.get("/api/branch/viewDept/"+this.address.branch_name)
        //   .then(response => {
        //     this.departments = response.data.departments;
        //   })
        //   .catch(error => {
        //     console.log(error);
        //   });
        // },
        fetchDepartment() {
            this.axios
                .get("/api/department/view")
                .then((response) => {
                    this.departments = response.data.department;
                    this.fetchCostDepartment();
                })
                .catch((error) => {
                    this.toast.error();
                });
        },
        populateAddress() {
            Object.keys(this.editData).map((element) => {
                if (element == "preferred") {
                    if (this.editData.preferred == null) {
                        this.address.preferred_address = 0;
                    } else {
                        this.address.preferred_address = 1;
                        this.address.preferred = this.editData.preferred;
                    }
                } else {
                    this.address[element] = this.editData[element];
                }
            });
            delete this.address.department;
            delete this.address.is_checked;
            this.fetchDepartment();
        },
        submit() {
            this.$refs.observer.validate();
            this.address.phone = this.address.phone.replace(/\s/g, '');
            if (this.edit) {
                this.updateAddress();
            } else {
                this.createAddress();
            }
        },
        async createAddress() {
            this.address.phone = this.address.phone.trim();
            try {
                const response = await this.axios.post("/api/address", this.address)
                this.toast.success(response.data.msg);
                this.$emit("closeModal");
            }
            catch (e) {
                console.log(e);
            }
        },
        async updateAddress() {
            this.address.phone = this.address.phone.trim();
            try {
                const response = await this.axios.put("/api/address/" + this.address.id, this.address)
                this.toast.success(response.data.msg);
                this.$emit("closeModal");
            }
            catch (e) {
                console.log(e);
            }
        },
        fetchLocationApi(type) {
            // return;
            let options = {
                types: ["geocode"],
            };
            let map = new google.maps.places.Autocomplete(this.$refs[type], {
                componentRestrictions: { country: [] },
                fields: ["address_components", "geometry"],
                types: ["address"],
            });
            map.addListener("place_changed", () => {
                let place = map.getPlace();

                this.address.address_1 = this.address.postal_code = "";
                for (const component of place.address_components) {
                    const componentType = component.types[0];

                    switch (componentType) {
                        case "street_number": {
                            this.address.address_1 = component.short_name;
                            break;
                        }
                        // case "administrative_area_level_2": {
                        //   this.draft.delivery_city = component.short_name;
                        //   break;
                        // }

                        case "route": {
                            this.address.address_1 = `${
                                this.address.address_1
                            } ${component.long_name}`;
                            break;
                        }

                        case "postal_code": {
                            this.address.postal_code = component.long_name;
                            //  this.draft.postcode = `${component.long_name}${postcode}`;
                            break;
                        }

                        case "postal_code_suffix": {
                            this.address.postcode = `${this.address.postcode}-${
                                component.long_name
                            }`;
                            break;
                        }
                        case "locality": {
                            this.address.city = component.short_name;
                            this.address.suburb = component.short_name;
                            break;
                        }

                        case "administrative_area_level_1": {
                            this.address.state = component.short_name;
                            break;
                        }
                        case "country":
                            this.address.country = component.long_name;
                            break;
                    }
                }

                console.log(place);
            });
        },
        clearData() {
            {
                this.address.company_name = "";
                this.address.branch_name = "";
                this.address.department_id = "";
                this.address.cost_center_id = "";
                this.address.delivery_location = "";
                this.address.address_1 = "";
                this.address.address_2 = "";
                this.address.postal_code = "";
                this.address.country = "";
                this.address.city = "";
                this.address.suburb = "";
                this.address.state = "";
                this.address.phone = "";
                this.address.email = "";
                this.address.preferred_address = "0";
                this.address.preferred = {
                    address_1: "",
                    address_2: "",
                    postal_code: "",
                    country: "",
                    city: "",
                    suburb: "",
                    state: "",
                };
            }
        },
        fetchCostDepartment() {
            if (this.address.department_id != "") {
                this.axios
                    .get("/api/cost/view/" + this.address.department_id)
                    .then((response) => {
                        this.costs = response.data.cost_centers;
                        if (this.costs.length > 0) {
                            if (!this.address.cost_center_id) {
                                this.address.cost_center_id = this.costs[0].id;
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
    },
    props: ["edit", "editData"],
};
</script>
<style lang="scss">
.popup_sub_container {
    padding: 20px;
    width: 100%;
    margin: auto;

    background: #fff;
}
.popup_sub_container > section {
    padding: 20px 0 0 0;
}

h3.sub_head {
    font-size: 20px;
    font-weight: 600;
    padding: 0;
}
.preferred_hide {
    display: none;
}
.chk_prefered {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin: 0 20px;
}
.chk_prefered:checked + hr + .preferred_hide {
    display: block;
}

.title_image {
    display: block;
    margin: auto;
}

.form_section1 {
    width: 100%;
    height: auto;
    padding: 20px;
    background: #ececec;
    margin: 20px 0;
}

i.closeAddContact {
    width: 30px;
    height: 30px;
    background: #000;
    color: #06a5ed;
    font-size: 14px;
    text-align: center;
    line-height: 30px;
    border-radius: 100px;
    display: block;
    font-style: normal;
    font-weight: bold;
    position: relative;
    float: right;
    right: 0;
    top: -60px;
    cursor: pointer;
}
</style>
<style scoped>
/*this fix is for table with 2 column only, to keep same width*/
.form_section1 table tr td {
    width: 50%;
}
</style>
