import { render, staticRenderFns } from "./ViewPending.vue?vue&type=template&id=357db6ec&scoped=true&3=2"
import script from "./ViewPending.vue?vue&type=script&lang=js&3=2"
export * from "./ViewPending.vue?vue&type=script&lang=js&3=2"
import style0 from "./ViewPending.vue?vue&type=style&index=0&lang=scss&3=2"
import style1 from "./ViewPending.vue?vue&type=style&index=1&id=357db6ec&scoped=true&lang=css&3=2"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "357db6ec",
  null
  
)

export default component.exports